var leap = [[24.834390282923472, 46.726942687059456],
  [24.83352596002162, 46.727552103129206],
  [24.833514698341, 46.72764251565651],
  [24.835829951487217, 46.732673596326904],
  [24.836033975492047, 46.73280474279101],
  [24.836160277748636, 46.73272712819991],
  [24.836830652819362, 46.73162980282343],
  [24.836767502398917, 46.73151471667984],
  [24.834392472564037, 46.726989756747095]]
var SpecialParking = [
  [24.835935991606018, 46.727812123357005],
  [24.834390528851333, 46.72677352796306],
  [24.836836063476674, 46.73148472831077],
  [24.836899750056897, 46.73148005153129],
  [24.837235175413724, 46.730839101980166],
  [24.837286128159633, 46.730549034909295],
  [24.835933619176323, 46.72780722380092],
]
var park = [
  [24.83785570338497, 46.737324372981526],
  [24.839167378105834, 46.73392127330064],
  [24.837369367189563, 46.733042293374716],
  [24.83707087410722, 46.733040009418836],
  [24.836896029262224, 46.73310922252916],
  [24.836788379054347, 46.7331556401303],
  [24.836657326616745, 46.73318142783453],
  [24.8364935108966, 46.73310406649334],
  [24.836423304103672, 46.73304733476273],
  [24.83587291824639, 46.73349083884446],
]
var park1 = [
  [24.828950517373396, 46.72444691815201],
  [24.82891025841422, 46.72389993162718],
  [24.829077960944453, 46.72341207417651],
  [24.829366416727588, 46.723027696459205],
  [24.829695126059907, 46.72279115125598],
  [24.83005067203883, 46.722680264728254],
  [24.830352552883397, 46.72268025652009],
  [24.832069954985403, 46.7261987521028],
  [24.83031904620291, 46.72734447579793]]

var VisitorsYellowShuttle = [[24.820496418619566, 46.70908343774435],
  [24.820159134245923, 46.70888879704677],
  [24.817292303494224, 46.711056536064945],
  [24.821105624899314, 46.7158434192276],
  [24.822617721724633, 46.71327505355195]]
var leapPath = leap.map((y) => {
  return { lat: y[0], lng: y[1] }
})
var SpecialParkingPath = SpecialParking.map((y) => {
  return { lat: y[0], lng: y[1] }
})
var parkPath = park.map((y) => {
  return { lat: y[0], lng: y[1] }
})
var park1Path = park1.map((y) => {
  return { lat: y[0], lng: y[1] }
})
var VisitorsYellowShuttlePath = VisitorsYellowShuttle.map((y) => {
  return { lat: y[0], lng: y[1] }
})
var marker = [
  {
    position: [24.835352308351325, 46.72963191240156],
    img: 'https://gcdnb.pbrd.co/images/hnKUay2TdxIv.png',
  },
  {
    position: [24.836287779512137, 46.7292490420719],
    img: 'https://developers.google.com/maps/documentation/javascript/examples/full/images/parking_lot_maps.png',
  },
  {
    position: [24.83782012388439, 46.7344521219667],
    img: 'https://developers.google.com/maps/documentation/javascript/examples/full/images/parking_lot_maps.png',
  },
  {
    position: [24.831075812684364, 46.72558728126527],
    img: 'https://developers.google.com/maps/documentation/javascript/examples/full/images/parking_lot_maps.png',
  },
  {
    position: [24.820516349336494, 46.71266163039018],
    img: 'http://cdn.onlinewebfonts.com/svg/img_27942.png',
  },
]
function drawPoly (google, smap) {
  const pPat = new google.maps.Polygon({
    paths: park1Path,
    strokeColor: '#FF0000',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: '#FF0000',
    fillOpacity: 0.35,
  })
  pPat.setMap(smap)
  const VisitorsPath = new google.maps.Polygon({
    paths: VisitorsYellowShuttlePath,
    strokeColor: '#FF0000',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: '#FF0000',
    fillOpacity: 0.35,
  })
  VisitorsPath.setMap(smap)
  const p1h = new google.maps.Polygon({
    paths: parkPath,
    strokeColor: '#FF0000',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: '#FF0000',
    fillOpacity: 0.35,
  })
  p1h.setMap(smap)
  const Specialth = new google.maps.Polygon({
    paths: SpecialParkingPath,
    strokeColor: '#FF0000',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: '#FF0000',
    fillOpacity: 0.35,
  })
  Specialth.setMap(smap)
  const lth = new google.maps.Polygon({
    paths: leapPath,
    strokeColor: '#58007b',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: '#58007b',
    fillOpacity: 0.35,
  })
  lth.setMap(smap)
  marker.forEach((e, i) => {
    var ic = {
      url: e.img, // url
      scaledSize: new google.maps.Size(25, 35), // scaled size
    }
    var ia = {
      url: e.img, // url
      scaledSize: new google.maps.Size(25, 35), // scaled size
    }
    // eslint-disable-next-line no-new
    new google.maps.Marker({
      position: new google.maps.LatLng(e.position[0], e.position[1]),
      map: smap,
      animation: i === 0 ? google.maps.Animation.BOUNCE : google.maps.Animation.DROP,
      icon: i === 4 ? ic : ia,
    })
  })
}
export {
  VisitorsYellowShuttlePath,
  park1Path,
  parkPath,
  SpecialParkingPath,
  leapPath,
  marker,
  drawPoly,
}
