<template>
  <div class="google-maps-page">
    <div class="row">
      <div class="flex md12 xs12">
        <va-card
          class="google-maps-page__widget"
          :title="$t('generic.Traf') +' '+ (time ? $t('generic.after') +' ' + time : ' ' +$t('generic.Now') )"
        >
          <!-- <span class="flex" slot="actions">
            <va-icon class="fa fa-circle"  style="color: #1300e8;"/>
            الطريق الافضل
          </span> -->
          <!-- <span class="flex" slot="actions">
            <va-icon class="fa fa-circle"   style="color: #6262d0;"/>
            طريق رئيسي
          </span>
          <span class="flex" slot="actions">
            <va-icon class="fa fa-circle"   style="color: #62c6d0;"/>
            طريق ثانوي
          </span> -->
          <!-- <span class="flex" slot="actions">
            <va-icon class="fa fa-circle"   style="color: #ffff55ee;"/>
            vms
          </span> -->
          <google-map :time="time"/>
        </va-card>
      </div>
    </div>
  </div>
</template>

<script>
import GoogleMap from './GoogleMap'

export default {
  name: 'google-maps-page',
  components: {
    GoogleMap,
  },
  props: {
    time: Number,
  },
  watch: {
    time: function () {
      location.reload()
    },
  },
}
</script>
