<template>
  <div>
    <div class="row mr-0 align--end">
      <div class="row pt-3 xs12 sm6 align--center">
        <div class="flex md6 sm12 xs12 px-1 text-start">
          <label class="input-label"> {{ $t("placeholder.ChooseRoute") }} </label>
          <a-select
            :show-search="true"
            :allowClear="true"
            :placeholder="$t('placeholder.ChooseRoute')"
            option-filter-prop="children"
            style="width: 100%;"
            v-model="routsSelect.value"
            mode="multiple"
            :notFoundContent="$t('placeholder.ItemsNotFound')"
          >
            <a-select-option
              :value="option.id"
              v-for="(option, i) in routsSelect.options"
              :key="i"
            >
              {{ option["RouteName"] }}
            </a-select-option>
          </a-select>
        </div>
        <div class="flex md6 sm12 mt-4 xs12 px-1 text-start">
          <va-checkbox :label="$t('placeholder.showTrafic')" v-model="showTrafic" />
        </div>
      </div>
      <div class="d-flex pt-3 px-1 flex xs12 sm6 m-0 justify--end align--center">
        <va-checkbox
          class="infotitlePr routeType"
          :label="$t('placeholder.showPrimariy')"
          v-model="showPrimariy"
        />
        <va-checkbox
          class="infotitleSc routeType"
          :label="$t('placeholder.showsec')"
          v-model="showsec"
        />
      </div>
    </div>
    <div class="google-map map-fill-height mb-3" ref="googleMap" style="height: 57vh;" />
    <va-data-table :fields="fields" :data="routesTable" no-pagination hoverable>
      <template slot="marker" slot-scope="props">
        {{ props.rowIndex + 1 }}
      </template>
      <template slot="DeafultTime" slot-scope="props">
        {{ props.rowData.DeafultTime + $t("minuits") }}
      </template>
      <template slot="TimeNow" slot-scope="props">
        {{ props.rowData.TimeNow + $t("minuits") }}
      </template>
      <template slot="distance" slot-scope="props">
        {{ props.rowData.distance + $t("KM") }}
      </template>
      <template slot="normal" slot-scope="props">
        {{
          props.rowData.normal
            ? `${props.rowData.normal} ${$t("KM")} (${props.rowData.normalPercentage})`
            : "----"
        }}
      </template>
      <template slot="slow" slot-scope="props">
        {{
          props.rowData.slow
            ? `${props.rowData.slow} ${$t("KM")} (${props.rowData.slowPercentage})`
            : "----"
        }}
      </template>
      <template slot="jam" slot-scope="props">
        {{
          props.rowData.jam
            ? `${props.rowData.jam} ${$t("KM")} (${props.rowData.jamPercentage})`
            : "----"
        }}
      </template>
      <template slot="actions" slot-scope="props">
        <va-button
          flat
          small
          color="info"
          icon="fa fa-eye"
          :title="$t('modal.view')"
          @click="showRouteModal(props.rowData.id)"
          class="ma-0"
        >
        </va-button>
      </template>
    </va-data-table>
  </div>
</template>

<script>
import * as GoogleMapsLoader from 'google-maps'
import firebase from 'firebase'
import * as spinners from 'epic-spinners'
import axios from 'axios'
import { drawPoly } from '../../../helper/Pahts'

export default {
  name: 'google-map',
  data () {
    return {
      distance: '',
      routes: [],
      routesTable: [],
      routsSelect: {
        value: [],
        options: [],
      },
      departureTime: null,
      iconPr: 'https://i.ibb.co/zScnP9L/IconPr.gif',
      iconSc: 'https://i.ibb.co/4t5K1jd/IconSec.gif',
      iconA: '',
      iconB: '',
      loading: true,
      googleMap: null,
      smap: null,
      showTrafic: false,
      showPrimariy: true,
      showsec: true,
      trafficLayer: null,
      markers: [],
      IW: null,
      alert: 'https://dm0qx8t0i9gc9.cloudfront.net/previews/audio/BsTwCwBHBjzwub4i4/car-horn-3_Gy26a2Nd_NWM.mp3',
    }
  },
  props: {
    time: Number,
  },
  computed: {
    fields () {
      return [
        {
          name: '__slot:marker',
          width: '30px',
          dataClass: 'text-center',
          title: '#',
        },
        {
          name: 'RouteName',
          title: this.$t('tables.headings.RouteName'),
          sortField: 'RouteName',
          width: '250px',
        },
        {
          name: '__slot:DeafultTime',
          title: this.$t('tables.headings.DeafultTime'),
          sortField: 'compareDeafultTime',
        },
        {
          name: '__slot:TimeNow',
          title: this.$t('tables.headings.TimeNow'),
          sortField: 'compareTimeNow',
        },
        {
          name: '__slot:distance',
          title: this.$t('tables.headings.distance'),
          sortField: 'comparedistance',
        },
        {
          name: '__slot:normal',
          title: this.$t('tables.headings.normal'),
          sortField: 'comparenormalPercentage',
        },
        {
          name: '__slot:slow',
          title: this.$t('tables.headings.slow'),
          sortField: 'compareslowPercentage',
        },
        {
          name: '__slot:jam',
          title: this.$t('tables.headings.jam'),
          sortField: 'comparejamPercentage',
        },
        {
          name: '__slot:actions',
          dataClass: 'text-right',
        },
      ]
    },
  },
  components: {
    ...spinners,
  },
  async mounted () {
    this.ClaearAllToasts()

    const firestore = firebase.firestore()
    const self = this

    await this.PageRender()
    setInterval(async () => {
      this.ClaearAllToasts()
      const t = new Date().toISOString()
      await firestore.collection('APIsResult').add({
        Date: t,
        apiArr: JSON.stringify(self.routes.map(x => {
          x.poly = ''
          return x
        })),
      })
      self.DeletAll()
      self.routes = []
      self.routesTable = []
      self.routsSelect = {
        value: [],
        options: [],
      }
      self.showTrafic = false
      self.showPrimariy = true
      self.showsec = true
      self.markers = []
      await this.PageRender()
    }, 300000)
  },
  methods: {
    async PageRender () {
      const self = this
      const time = Number(this.time)
      if (time > 0) {
        var newTimeAfter = new Date()
        newTimeAfter.setMinutes(newTimeAfter.getMinutes() + time)
        this.departureTime = newTimeAfter.toISOString()
      }
      GoogleMapsLoader.KEY = 'AIzaSyC6Wt_40qWZ7up5hvZi9GwzxYWmwjnXggs'
      GoogleMapsLoader.VERSION = '3.35'
      await GoogleMapsLoader.load(async (google) => {
        this.googleMap = google
        self.smap = new google.maps.Map(this.$refs.googleMap, {
          center: new google.maps.LatLng(24.774265, 46.738586),
          zoom: 11,
          mapTypeId: google.maps.MapTypeId.ROADMAP,
        })
        drawPoly(google, self.smap)
        self.IW = new google.maps.InfoWindow()
        self.trafficLayer = new google.maps.TrafficLayer()
        self.iconA = {
          url: 'http://maps.google.com/mapfiles/kml/paddle/A.png', // url
          scaledSize: new google.maps.Size(35, 35), // scaled size
        }
        self.iconB = {
          url: 'http://maps.google.com/mapfiles/kml/paddle/B.png', // url
          scaledSize: new google.maps.Size(35, 35), // scaled size
        }
        const firestore = firebase.firestore()
        await firestore
          .collection('routes')
          .get()
          .then(async (querySnapshot) => {
            querySnapshot.forEach((doc, index) => {
              var obj = doc.data()
              obj.id = doc.id
              self.routes.push(obj)
              var LongLat = obj.FromLocation.split(',')
              var org = new google.maps.LatLng(Number(LongLat[0]), Number(LongLat[1]))
              var LongLatDes = obj.toLocation.split(',')
              var destination = new google.maps.LatLng(
                Number(LongLatDes[0]),
                Number(LongLatDes[1]),
              )
              axios
                .post(
                  'https://map-api-ubzex7qxpa-ew.a.run.app/routes/computeRouteWithTrafficLength',
                  {
                    origin: org,
                    destination: destination,
                    waypoints: obj.waypoints,
                    departureTime: self.departureTime,
                  },
                )
                .then((resp) => {
                  console.log('data', resp.data)
                  self.render(resp.data[0], doc.id)
                  const mo = new google.maps.Marker({
                    position: new google.maps.LatLng(org),
                    map: self.smap,
                    title: obj.RouteName,
                    animation: google.maps.Animation.DROP,
                    icon: self.iconA,
                  })
                  mo.addListener('click', (eve) => {
                    self.showIW(obj, eve.latLng)
                  })
                  self.markers.push(mo)
                  // eslint-disable-next-line no-new
                  // const md = new google.maps.Marker({
                  //   position: new google.maps.LatLng(destination),
                  //   map: self.smap,
                  //   title: obj.RouteName,
                  //   animation: google.maps.Animation.DROP,
                  //   icon: self.iconB,
                  // })
                  // md.addListener('click', (eve) => {
                  //   self.showIW(obj, eve.latLng)
                  // })
                  // self.markers.push(md)
                })
                .catch((err) => {
                  console.error(err)
                })
            })
          })
      })
    },
    render (route, id) {
      const self = this
      if (!route) {
        console.log(route, id, 'dddddddddddddd')
        return
      }
      const decodedPath = route.polyline.geoJsonLinestring.coordinates
      const decodedPathLatLng = decodedPath.map((elt) => {
        return { lat: elt[1], lng: elt[0] }
      })
      const speedReadingIntervals = route.travelAdvisory?.speedReadingIntervals || []
      var Routeindex = this.routes.findIndex((x) => x.id === id)
      if (Routeindex > -1) {
        this.routes[Routeindex].poly = []
        this.routes[Routeindex].ApiData = route
        this.routesTable.push({
          id: id,
          RouteName: this.routes[Routeindex]?.RouteName,
          jam: (route.statistics.jam.distance.value / 1000).toFixed(2).toString(),
          slow: (route.statistics.slow.distance.value / 1000).toFixed(2).toString(),
          normal: (route.statistics.normal.distance.value / 1000).toFixed(2).toString(),
          jamPercentage: route.statistics.jam.distance.percentage.toString(),
          slowPercentage: route.statistics.slow.distance.percentage.toString(),
          normalPercentage: (
            route.statistics.normal.distance.percent +
            1 +
            '%'
          ).toString(),
          DeafultTime: Math.round(route.statistics.staticDuration.value / 60).toString(),
          TimeNow: Math.round(route.statistics.duration.value / 60).toString(),
          distance: (route.statistics.distance.value / 1000).toFixed(2).toString(),
          comparejamPercentage: route.statistics.jam.distance.percent,
          compareslowPercentage: route.statistics.slow.distance.percent,
          comparenormalPercentage: route.statistics.normal.distance.percent + 1,
          comparedistance: route.statistics.distance.value,
          compareDeafultTime: route.statistics.staticDuration.value,
          compareTimeNow: route.statistics.duration.value,
        })
        if (route.statistics.jam.distance.percent > 40) {
          this.showToast(`${this.$t('generic.route')} ${this.routes[Routeindex]?.RouteName} ${this.$t('generic.JamedBy')} ${route.statistics.jam.distance.percentage.toString()} `, {
            icon: 'fa-exclamation',
            position: 'bottom-left',
            duration: null,
            type: 'error',
            action: [
              {
                icon: 'fa-eye',

                onClick: (e, toastObject) => {
                  self.showRouteModal(id)
                },
              },
              {
                icon: 'fa-times',

                onClick: (e, toastObject) => {
                  toastObject.goAway(0)
                },
              }],
          })
          new Audio(this.alert).play()
        }
      } else {
        console.error(`can not find route with id ${id}`)
      }
      speedReadingIntervals.forEach((interval) => {
        // console.log(`interval`, interval);
        const startIndex = interval.startPolylinePointIndex || 0
        const endIndex = interval.endPolylinePointIndex || 0
        const type = interval.speed
        let poly = decodedPathLatLng.slice(startIndex, endIndex + 1)
        var colors = { TRAFFIC_JAM: '#d74342', SLOW: '#e69e01', NORMAL: '#4078c9' }
        poly = new this.googleMap.maps.Polyline({
          path: poly,
          strokeColor: colors[type],
          geodesic: true,
          strokeOpacity: 1,
          strokeWeight: 6,
        })
        this.googleMap.maps.event.addListener(poly, 'click', function (evt) {
          self.showIW(self.routes[Routeindex], evt.latLng)
        })
        poly.setMap(self.smap)
        if (Routeindex > -1) {
          this.routes[Routeindex].poly.push(poly)
        }
      })
    },
    showIW (route, position) {
      const data = route?.ApiData
      let html = ''
      html += '<div dir="ltr">'
      html += `<span class="${route.iSPrimary ? 'infotitlePr' : 'infotitleSc'}">${
        route.RouteName
      }</span><br/>`
      html += `<span class="infosegment">${this.$t('distance')}: ${
        data.statistics.distance.value / 1000
      } ${this.$t('KM')} </span><br/>`
      html += `<span class="infosegment"> ${this.$t('TimeWithoutCrowding')}: ${Math.round(
        route.DeafultTime / 60,
      )} ${this.$t('minuits')}</span><br/>`
      html += `<span class="infosegment"> ${this.$t('timeNow')}: ${this.fromatTime(
        data.statistics.duration.value,
        true,
      )}</span><br/>`

      html += '<br/>'
      html += `<span class="infonormal">${this.$t('normal')}: ${
        data.statistics.normal.distance.value / 1000
      } ${this.$t('KM')} (${data.statistics.normal.distance.percentage})</span><br/>`
      html += `<span class="infoslow">${this.$t('Slow')}: ${
        data.statistics.slow.distance.value / 1000
      } ${this.$t('KM')}  (${data.statistics.slow.distance.percentage})</span><br/>`
      html += `<span class="infojam">${this.$t('crowded')}: ${
        data.statistics.jam.distance.value / 1000
      } ${this.$t('KM')}  (${data.statistics.jam.distance.percentage})</span><br/>`
      html += '</div>'

      if (this.IW) {
        this.IW.setContent(html)
        this.IW.setPosition(position)
        this.IW.open({ map: this.smap })
      }

      console.log('showin poly click', route)
    },
    fromatTime (time, isSec = false) {
      let text

      const durationInSec = isSec ? parseInt(time) : parseInt(time) * 60

      if (durationInSec < 60) text = `${durationInSec}${this.$t('second')}`
      text = `${Math.round(durationInSec / 60)} ${this.$t('minuits')}`
      return text
    },
    renderAll () {
      const self = this
      this.routes.forEach((el) => {
        if (el.poly) {
          el.poly.forEach((stepPoly) => {
            // eslint-disable-next-line no-unused-expressions
            stepPoly?.setMap(this.smap)
          })
        }
        var LongLat = el.FromLocation.split(',')
        var org = new this.googleMap.maps.LatLng(Number(LongLat[0]), Number(LongLat[1]))
        // var LongLatDes = el.toLocation.split(',')
        // var destination = new this.googleMap.maps.LatLng(
        //   Number(LongLatDes[0]),
        //   Number(LongLatDes[1]),
        // )
        const mo = new this.googleMap.maps.Marker({
          position: new this.googleMap.maps.LatLng(org),
          map: this.smap,
          title: el.RouteName,
          animation: this.googleMap.maps.Animation.DROP,
          icon: self.iconA,
        })
        mo.addListener('click', (eve) => {
          self.showIW(el, eve.latLng)
        })

        this.markers.push(mo)
        // eslint-disable-next-line no-new
        // const md = new this.googleMap.maps.Marker({
        //   position: new this.googleMap.maps.LatLng(destination),
        //   map: this.smap,
        //   title: el.RouteName,
        //   animation: this.googleMap.maps.Animation.DROP,
        //   icon: self.iconB,
        // })
        // md.addListener('click', (eve) => {
        //   self.showIW(el, eve.latLng)
        // })
        // this.markers.push(md)
      })
      this.recenter()
    },
    DeletAll () {
      this.routes.forEach((el) => {
        if (el.poly) {
          el.poly.forEach((stepPoly) => {
            // eslint-disable-next-line no-unused-expressions
            stepPoly?.setMap(null)
          })
        }
      })
      this.markers.forEach((m) => {
        // eslint-disable-next-line no-unused-expressions
        m?.setMap(null)
      })
      this.recenter()
    },
    drawOnlyRoute (route) {
      const self = this

      if (route.poly) {
        for (let i = 0; i < route.poly.length; i++) {
          route.poly[i].setMap(this.smap)
        }
        var LongLat = route.FromLocation.split(',')
        var org = new this.googleMap.maps.LatLng(Number(LongLat[0]), Number(LongLat[1]))
        // var LongLatDes = route.toLocation.split(',')
        // var destination = new this.googleMap.maps.LatLng(
        //   Number(LongLatDes[0]),
        //   Number(LongLatDes[1]),
        // )
        const mo = new this.googleMap.maps.Marker({
          position: new this.googleMap.maps.LatLng(org),
          map: this.smap,
          title: route.RouteName,
          animation: this.googleMap.maps.Animation.DROP,
          icon: self.iconA,
        })
        mo.addListener('click', (eve) => {
          self.showIW(route, eve.latLng)
        })
        this.markers.push(mo)
        // eslint-disable-next-line no-new
        // const md = new this.googleMap.maps.Marker({
        //   position: new this.googleMap.maps.LatLng(destination),
        //   map: this.smap,
        //   title: route.RouteName,
        //   animation: this.googleMap.maps.Animation.DROP,
        //   icon: self.iconB,
        // })
        // md.addListener('click', (eve) => {
        //   self.showIW(route, eve.latLng)
        // })
        // this.markers.push(md)
        this.recenter()
      }
    },
    showRouteModal (id) {
      this.routsSelect.value = [id]
      const position = document.getElementsByClassName('google-map')[0].offsetTop
      // smooth scroll
      document
        .getElementsByTagName('main')[0]
        .scrollTo({ top: position, behavior: 'smooth' })
      this.recenter()
    },
    recenter () {
      this.smap.panTo(new this.googleMap.maps.LatLng(24.774265, 46.738586))
      this.smap.setZoom(11)
    },
  },
  watch: {
    showTrafic: function () {
      if (this.trafficLayer != null) {
        this.showTrafic
          ? this.trafficLayer.setMap(this.smap)
          : this.trafficLayer.setMap(null)
      }
    },
    showPrimariy: function () {
      this.DeletAll()
      if (this.showsec && this.showPrimariy) {
        this.routsSelect.value = []
      } else if (!this.showsec && this.showPrimariy) {
        this.DeletAll()
        const newRoutes = []
        this.routes.forEach((route) => {
          if (route.iSPrimary) {
            newRoutes.push(route.id)
          }
        })
        if (newRoutes.length > 0) {
          this.routsSelect.value = newRoutes
        }
      } else if (this.showsec && !this.showPrimariy) {
        this.DeletAll()
        const newRoutes = []
        this.routes.forEach((route) => {
          if (!route.iSPrimary) {
            newRoutes.push(route.id)
          }
        })
        if (newRoutes.length > 0) {
          this.routsSelect.value = newRoutes
        }
      } else {
        this.routsSelect.value = []
      }
    },
    showsec: function () {
      this.DeletAll()
      if (this.showsec && this.showPrimariy) {
        this.routsSelect.value = []
      } else if (!this.showsec && this.showPrimariy) {
        this.DeletAll()
        const newRoutes = []
        this.routes.forEach((route) => {
          if (route.iSPrimary) {
            newRoutes.push(route.id)
          }
        })
        if (newRoutes.length > 0) {
          this.routsSelect.value = newRoutes
        }
      } else if (this.showsec && !this.showPrimariy) {
        this.DeletAll()
        const newRoutes = []
        this.routes.forEach((route) => {
          if (!route.iSPrimary) {
            newRoutes.push(route.id)
          }
        })
        if (newRoutes.length > 0) {
          this.routsSelect.value = newRoutes
        }
      } else {
        this.routsSelect.value = []
      }
    },
    routes: function () {
      this.routsSelect.options = this.routes
    },
    'routsSelect.value': function () {
      if (this.routsSelect.value.length > 0) {
        this.DeletAll()
        this.routsSelect.value.forEach((id) => {
          console.log(id)
          const r = this.routes.find((x) => x.id === id)
          this.drawOnlyRoute(r)
        })
      } else {
        this.DeletAll()
        this.renderAll()
      }
      console.log('routsSelect.value change', this.routsSelect.value)
    },
  },
}
</script>

<style lang="scss">

.toasted-container.bottom-left {
  width: 23vw !important;
}

.routeType {
  max-width: 130px;
  text-align: end;
}

.infojam {
  color: #d74342;
  font-weight: 700;
}

.infonormal {
  color: #4078c9;
}

.infoslow {
  color: #e69e01;
}

.infotitlePr {
  color: #6262d0;
  font-weight: 700;
}

.infotitleSc {
  color: #62c6d0;
  font-weight: 700;
}

.infosegment {
  color: #2d2c2c;
  font-weight: 700;
}

.gm-style-iw-c {
  direction: ltr;
}

.map-fill-height {
  height: 83%;
}

.adp-substep .adp-stepicon .adp-maneuver {
  background-size: 19px 630px;
  position: absolute;
  left: 0;
  width: 16px;
  height: 16px;
  background-image: url(http://maps.gstatic.com/mapfiles/api-3/images/maneuvers.png);
}

.adp-substep .adp-stepicon .adp-maneuver.adp-ferry {
  background-position: 0 -614px;
}

.adp-substep .adp-stepicon .adp-maneuver.adp-ferry-train {
  background-position: 0 -566px;
}

.adp-substep .adp-stepicon .adp-maneuver.adp-merge {
  background-position: 0 -143px;
}

.adp-substep .adp-stepicon .adp-maneuver.adp-straight {
  background-position: 0 -534px;
}

.adp-substep .adp-stepicon .adp-maneuver.adp-fork-left {
  background-position: 0 -550px;
}

.adp-substep .adp-stepicon .adp-maneuver.adp-ramp-left {
  background-position: 0 -598px;
}

.adp-substep .adp-stepicon .adp-maneuver.adp-roundabout-left {
  background-position: 0 -197px;
}

.adp-substep .adp-stepicon .adp-maneuver.adp-turn-left {
  background-position: 0 -413px;
}

.adp-substep .adp-stepicon .adp-maneuver.adp-turn-sharp-left {
  background-position: 0 0;
}

.adp-substep .adp-stepicon .adp-maneuver.adp-turn-slight-left {
  background-position: 0 -378px;
}

.adp-substep .adp-stepicon .adp-maneuver.adp-uturn-left {
  background-position: 0 -305px;
}

.adp-substep .adp-stepicon .adp-maneuver.adp-fork-right {
  background-position: 0 -499px;
}

.adp-substep .adp-stepicon .adp-maneuver.adp-ramp-right {
  background-position: 0 -429px;
}

.adp-substep .adp-stepicon .adp-maneuver.adp-roundabout-right {
  background-position: 0 -232px;
}

.adp-substep .adp-stepicon .adp-maneuver.adp-turn-right {
  background-position: 0 -483px;
}

.adp-substep .adp-stepicon .adp-maneuver.adp-turn-sharp-right {
  background-position: 0 -582px;
}

.adp-substep .adp-stepicon .adp-maneuver.adp-turn-slight-right {
  background-position: 0 -51px;
}

.adp-substep .adp-stepicon .adp-maneuver.adp-uturn-right {
  background-position: 0 -35px;
}

i.fa.fa-exclamation {
  color: red !important;
}

.va-modal__inner {
  max-width: 100%;
}

.unitsNames-width {
  max-width: 177px;
}

#sidebar {
  flex-basis: 15rem;
  flex-grow: 1;
  padding: 1rem;
  max-width: 30rem;
  height: 69vh;
  box-sizing: border-box;
  overflow: auto;
}

#map {
  flex-basis: 0;
  flex-grow: 4;
  height: 69vh;
}

.va-modal__close {
  right: auto;
  left: 1rem;
}
</style>
